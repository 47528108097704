var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.clients.length > 0)?_c('v-card',{staticClass:"ma-0 pa-0",style:(`border-radius: 10px;`),attrs:{"flat":"","outlined":""},on:{"click":function($event){return _vm.openQuote()}}},[_c('div',{staticClass:"pa-0 ma-0"},[_c('v-row',{staticClass:"d-flex flex-wrap font-weight-bold mx-2 mt-2 mb-0 pa-0"},[_c('v-col',{staticClass:"pa-0 ma-0 d-flex",attrs:{"cols":"10"}},[(_vm.quote.client || _vm.client)?_c('p',{class:_vm.colorClass,staticStyle:{"font-weight":"bold !important"}},[_vm._v(" "+_vm._s(_vm.quote.client ? _vm.quote.client.name : _vm.client.name)+" ")]):_vm._e(),(_vm.collaborators == 0)?_c('v-icon',{staticClass:"ml-2 mt-n4",attrs:{"color":"red"}},[_vm._v("mdi-account-multiple-remove")]):_vm._e()],1),_c('v-col',{staticClass:"d-flex pa-0 ma-0 justify-end",attrs:{"cols":"2"}},[_c('v-row',{staticClass:"d-flex mt-1 justify-end"},[_c('div',[_c('v-btn',{attrs:{"icon":"","small":"","color":"grey lighten-3"},on:{"click":function($event){$event.stopPropagation();return _vm.openFollowUp.apply(null, arguments)}}},[_c('v-icon',{attrs:{"color":_vm.color}},[_vm._v("mdi-chat-outline")])],1)],1),_c('div',[_c('v-menu',{attrs:{"rounded":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ attrs, on }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"grey lighten-3","depressed":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.color}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,1408273771)},[_c('v-list',{attrs:{"dense":""}},[(_vm.createHistory)?_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.archiveDialog.apply(null, arguments)}}},[_c('v-list-item-title',[_vm._v(" Archive ")])],1):_vm._e(),(_vm.quote.status == 'OPEN')?_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.quoteApproval.apply(null, arguments)}}},[_c('v-list-item-title',[_vm._v(" For Approval ")])],1):_vm._e(),(
                                        (_vm.quote.status == 'SENT' ||
                                            _vm.quote.status == 'APPROVAL') &&
                                            _vm.createHistory
                                    )?_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.rejectedDialog.apply(null, arguments)}}},[_c('v-list-item-title',[_vm._v(" Lost ")])],1):_vm._e(),(
                                        _vm.quote.status == 'SENT' ||
                                            _vm.quote.status == 'APPROVAL'
                                    )?_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.reOpenQuote.apply(null, arguments)}}},[_c('v-list-item-title',[_vm._v(" Modify ")])],1):_vm._e(),(
                                        _vm.quote.status == 'SENT' &&
                                            _vm.createHistory
                                    )?_c('v-list-item',{on:{"click":_vm.quoteAwarded}},[_c('v-list-item-title',[_vm._v(" Awarded ")])],1):_vm._e()],1)],1)],1)])],1)],1),_c('v-row',{staticClass:"d-flex flex-wrap font-weight-regular mt-n3 mb-0 mx-2 pa-0"},[_c('v-col',{staticClass:"pa-0 ma-0",attrs:{"cols":"12"}},[_c('p',{staticClass:"ma-0 pa-0"},[_vm._v(" ("+_vm._s(_vm.quote.number)+") "+_vm._s(_vm.quote.name)+" ")])]),_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"12"}},[_c('v-list-item-subtitle',{staticClass:"mt-0"},[_vm._v(_vm._s(_vm.quote.updatedOn))])],1),_c('v-col',{staticClass:"d-flex justify-end mb-2 mt-0 mr-2 ml-0 pa-0",attrs:{"cols":"12"}},[_c('span',{staticClass:"pb-0 font-weight-medium"},[_vm._v(" "+_vm._s(new Intl.NumberFormat('de-DE', { style: 'currency', currency: _vm.quote.currency, maximumFractionDigits: 2, }).format(_vm.quote.totalCosts ? _vm.quote.totalCosts : 0))+" ")])])],1)],1),_c('v-dialog',{attrs:{"persistent":"","retain-focus":false},model:{value:(_vm.openFollowUpDialog),callback:function ($$v) {_vm.openFollowUpDialog=$$v},expression:"openFollowUpDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_c('v-btn',{staticClass:"mr-3",attrs:{"depressed":"","color":"primary","icon":"","rounded":""},on:{"click":_vm.closeFollowUp}},[_c('v-icon',[_vm._v("mdi-close")])],1),_vm._v(" "+_vm._s(_vm.quote.name)+" - Follow Up "),_c('v-spacer')],1),_c('v-divider',{staticClass:"ml-4 mr-5"}),_c('div',{staticClass:"px-2 py-2"},[_c('FollowUp',{attrs:{"id":this.quote.id,"quote":this.quote,"settings":_vm.settings}})],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }